import type { InductionsActionsUnionType } from './actions';
import { InductionsActionTypes } from './actions';
import { initialInductionsState } from './constants';
import type { InductionsState } from './types';

const inductionsReducer = (
  state: InductionsState = initialInductionsState,
  action: InductionsActionsUnionType
): InductionsState => {
  switch (action.type) {
    /**
     * [Inductions]
     * For fetching all the Inductions
     *
     */
    case InductionsActionTypes.FETCH_ALL_ASSIGNED_INDUCTIONS: {
      return {
        ...state,
        fetchingInductionsLoading: true,
        fetchingInductionsErrorCode: undefined
      };
    }

    case InductionsActionTypes.FETCH_ALL_ASSIGNED_INDUCTIONS_SUCCESS: {
      const { inductionsData } = action.payload;
      return {
        ...state,
        fetchingInductionsLoading: false,
        fetchingInductionsErrorCode: undefined,
        inductionsData: {
          ...state.inductionsData,
          total_size: inductionsData.total_size,
          induction_progresses: [...inductionsData.induction_progresses]
        }
      };
    }

    case InductionsActionTypes.FETCH_ALL_ASSIGNED_INDUCTIONS_FAILURE: {
      const errorCode = typeof action.payload === 'string' ? action.payload : action.payload.error;
      return {
        ...state,
        fetchingInductionsLoading: false,
        fetchingInductionsErrorCode: errorCode
      };
    }

    default:
      return state;
  }
};

export { inductionsReducer };
