import { t } from 'i18next';
import { isEmpty, isString } from 'lodash';

import { ErrorLogger } from '@edapp/monitoring';
import { ENV } from '@maggie/config/env';
import { Platform } from '@maggie/cordova/platform';
import { StatusBarManager } from '@maggie/cordova/status-bar';

export class IFrameBridge {
  iFrameElement: HTMLIFrameElement | undefined;
  parentElement: HTMLElement;

  constructor(parentSelector: string) {
    this.parentElement = document.querySelector(parentSelector)!;
    if (!this.parentElement) {
      throw Error(`Cannot find parent element in the DOM with selector: ${parentSelector}`);
    }
  }

  private handleWillShowKeyboard = (e: CordovaKeyboardEvent) => {
    if (this.iFrameElement) {
      this.iFrameElement.style.paddingBottom = `${e.keyboardHeight + 30}px`;
    }
  };

  private handleWillHideKeyboard = () => {
    if (this.iFrameElement) {
      this.iFrameElement.style.paddingBottom = `0px`;
    }
  };

  /**
   * The event handler for messages passed from IFrame lesson windows. This handler runs `eval()` on the code
   * string in event.data
   * @param {MessageEvent} event A message event from the IFrame window
   */
  private handleMessageEvents = (event: MessageEvent) => {
    const acceptedThomasOrigins = [
      ENV.THOMAS, // web
      'ionic://localhost', // iOS
      'file://', // Android
      ENV.SC_WEBVIEW_ANDROID_AU,
      ENV.SC_WEBVIEW_ANDROID_EU,
      ENV.SC_WEBVIEW_ANDROID_US,
      ENV.SC_WEBVIEW_ANDROID_SANDPIT,
      ENV.SC_WEBVIEW_IOS_AU,
      ENV.SC_WEBVIEW_IOS_EU,
      ENV.SC_WEBVIEW_IOS_US,
      ENV.SC_WEBVIEW_IOS_SANDPIT
    ];
    if (process.env.NODE_ENV !== 'development' && !acceptedThomasOrigins.includes(event.origin)) {
      return; // ignore message - not coming from thomas
    }

    if (process.env.NODE_ENV === 'development') {
      if (typeof event.data === 'string' && event.data.includes('webpackHotUpdate')) {
        return;
      }
    }

    // Catch for segment events spam
    if (event.data.indexOf?.('{') === 0 || !isString(event.data) || isEmpty(event.data)) {
      return;
    }

    if (event.data.includes('setImmediate$')) {
      // ignore - this event we don't know where it's from but we don't care
      // too many of this in sentry
      return;
    }

    if (!event.data.match('window.SCORMED') && event.data.indexOf('EdAppEvents') < 0) {
      try {
        eval(event.data);
      } catch (error) {
        ErrorLogger.captureEvent('Exception on posting a message in iframe_bridge', 'error', {
          eventData: event.data,
          error: error
        });
      }
    }
  };

  /**
   * Fire an event to let the lesson window know that the main Ed window has closed
   * @param {Window} this The Thomas IFrame Window object
   */
  private handleUnloadWindow = () => {
    if (!this.iFrameElement?.contentWindow) {
      throw Error(`Unload window and no iframe instance.`);
    }

    this.iFrameElement.contentWindow.postMessage("Backbone.Events.trigger('ed-closed')", '*');
  };

  private addListeners = () => {
    window.addEventListener('message', this.handleMessageEvents);
    window.addEventListener('unload', this.handleUnloadWindow);
    window.addEventListener('pagehide', this.handleUnloadWindow);

    if (Platform.get() === 'Android') {
      window.addEventListener('keyboardWillShow', this.handleWillShowKeyboard);
      window.addEventListener('keyboardWillHide', this.handleWillHideKeyboard);
    }
  };

  private removeListeners = () => {
    window.removeEventListener('message', this.handleMessageEvents);
    window.removeEventListener('unload', this.handleUnloadWindow);
    window.removeEventListener('pagehide', this.handleUnloadWindow);

    if (Platform.get() === 'Android') {
      window.removeEventListener('keyboardWillShow', this.handleWillShowKeyboard);
      window.removeEventListener('keyboardWillHide', this.handleWillHideKeyboard);
    }
  };

  public createIframe = (iFrameUrl: string, platform: string) => {
    return new Promise<void>((resolve, reject) => {
      const ifr = document.createElement('iframe');
      ifr.setAttribute('allowfullscreen', 'allowfullscreen');
      ifr.setAttribute('allow', 'autoplay');
      ifr.src = iFrameUrl;
      ifr.id = 'lesson-iframe';
      ifr.title = t('lesson.content', { ns: 'learners-experience' });
      ifr.classList.add('hidden');
      ifr.dataset.platform = platform.includes('browser') ? 'browser' : platform;

      ifr.onload = () => {
        this.addListeners();

        ifr.classList.remove('hidden');
        // TODO: https://safetyculture.atlassian.net/browse/TRAINING-523
        StatusBarManager.hide();
        resolve();
      };

      ifr.onerror = err => {
        ErrorLogger.captureEvent('iframe-bridge fail to load iframe', 'fatal', { err });
        this.destroyIframe();
        reject(err);
      };

      ifr.onabort = err => {
        ErrorLogger.captureEvent('iframe-bridge aborted', 'fatal', { err });
        this.destroyIframe();
        reject(err);
      };

      /**
       * Do a timeout to go in the next thread cycle.
       *
       * If we appendChild straight away thomas will have an invalid innerWidth & innerHeight.
       * The invalid values of innerWidth & innerHeight will cause `iostap` to behave unexpectedly.
       *
       * This issue is more often in local. It was never seen in production.
       */
      setTimeout(() => {
        this.iFrameElement = ifr;
        this.parentElement.appendChild(this.iFrameElement);
      }, 0);
    });
  };

  /**
   * Note: we cannot use the handy [transfer] arg for IE8,9 support
   *
   * (not sure what this comment means, ask Tim.W)
   */
  public executeScript = (code: string) => {
    this.iFrameElement =
      this.iFrameElement || (document.getElementById('lesson-iframe') as HTMLIFrameElement);

    if (!this.iFrameElement?.contentWindow) {
      ErrorLogger.captureEvent('iframe-bridge fail to execute script', 'error', {
        code,
        iframe: this.iFrameElement
      });
      return;
    }

    // TODO: https://safetyculture.atlassian.net/browse/TRAINING-524
    this.iFrameElement.contentWindow.postMessage(code, '*');
  };

  public destroyIframe = () => {
    // TODO: https://safetyculture.atlassian.net/browse/TRAINING-523
    StatusBarManager.show();

    if (this.iFrameElement) {
      this.iFrameElement.classList.add('hidden');

      this.removeListeners();

      if (this.parentElement.contains(this.iFrameElement)) {
        this.parentElement.removeChild(this.iFrameElement);
      }
      this.iFrameElement = undefined;
    }
  };
}
