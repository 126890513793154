import type { ActionsUnion, ActionWithPayload } from '@edapp/utils';
import { createAction } from '@edapp/utils';
import type { InductionsInstance, InductionsOptions } from './types';

export enum InductionsActionTypes {
  FETCH_ALL_ASSIGNED_INDUCTIONS = 'FETCH_ALL_ASSIGNED_INDUCTIONS',
  FETCH_ALL_ASSIGNED_INDUCTIONS_SUCCESS = 'FETCH_ALL_ASSIGNED_INDUCTIONS_SUCCESS',
  FETCH_ALL_ASSIGNED_INDUCTIONS_FAILURE = 'FETCH_ALL_ASSIGNED_INDUCTIONS_FAILURE'
}

export const InductionsActions = {
  fetchAllInductions: (options: InductionsOptions) =>
    createAction(InductionsActionTypes.FETCH_ALL_ASSIGNED_INDUCTIONS, { options }),
  fetchAllInductionsSuccess: (page: number, inductionsData: InductionsInstance) =>
    createAction(InductionsActionTypes.FETCH_ALL_ASSIGNED_INDUCTIONS_SUCCESS, {
      page,
      inductionsData
    }),
  fetchAllInductionsFailure: (error: string) =>
    createAction(InductionsActionTypes.FETCH_ALL_ASSIGNED_INDUCTIONS_FAILURE, {
      error
    })
};

export type InductionsActionsUnionType =
  | ActionsUnion<typeof InductionsActions>
  | ActionWithPayload<InductionsActionTypes.FETCH_ALL_ASSIGNED_INDUCTIONS_FAILURE, string>;
