import { put, select, takeLatest } from 'redux-saga/effects';

import { ThomasPlayerMemo } from '@maggie/core/lessons/thomas-player/lesson-player-memo';
import type { RouteParams } from '@maggie/core/router/types';
import { AssessmentActions } from '@maggie/store/courseware/assessments/actions';
import { DiscussionActions } from '@maggie/store/courseware/discussions/actions';
import type { LxStoreState } from '@maggie/store/types';

import { RapidRefreshActions } from '../courseware/rapid-refreshes/actions';
import { NavigationActionTypes } from './actions';
import type { NavigationAction } from './types';
import { findParentRoute, isRoute, strictQueryParams } from './utils';

enum GoBackSources {
  Home = 'home',
  Inductions = 'inductions'
}

interface QueryParams {
  source?: GoBackSources;
  prevPath?: string;
}

const goBackPath = (query: QueryParams) => {
  switch (query.source) {
    case GoBackSources.Inductions:
      window.location.replace(query?.prevPath || '/home');
      return;
    case GoBackSources.Home:
    default:
      window.location.replace('/home');
      return;
  }
};

export function* handleGoBack(action: NavigationAction<NavigationActionTypes.GO_BACK>): any {
  const { params, route } = action.payload;

  /**
   * For any route, when going back, check for the query parameter ?source
   * If exists, use that to navigate back.
   */
  const { query } = strictQueryParams<any, QueryParams>(params);
  if (query.source) {
    // TODO: https://safetyculture.atlassian.net/browse/TRAINING-530
    goBackPath(query);
    return;
  }

  const state: LxStoreState = yield select<LxStoreState>(s => s);

  const parent = findParentRoute(route, params, state);

  if (isRoute(route, params, 'discussion')) {
    const discussionId = params.id;
    yield put(DiscussionActions.clearDiscussionPosts(discussionId));
  }

  if (isRoute(route, params, 'assignment')) {
    const assessmentId = params.id;
    yield put(AssessmentActions.clearAssessmentPosts(assessmentId));
  }

  if (isRoute(route, params, 'groupTraining') && !parent) {
    window.__router.navigate('home', {});
    return;
  }

  if (isRoute(route, params, 'homeSection') && !parent) {
    yield put(RapidRefreshActions.resetRapidRefreshList());
    window.__router.navigate('home', {}, true);
    return;
  }

  if (isRoute(route, params, 'rapidRefresh')) {
    if (ThomasPlayerMemo.hasPlayer(params.id)) {
      // close player before navigating away to avoid 'lesson-unloaded' event from thomas
      ThomasPlayerMemo.getPlayer(params.id)?.close();
    }
    window.__router.navigate('home', {}, true);
    return;
  }

  if (isRoute(route, params, 'brainBoost')) {
    window.__router.navigate('home', {}, true);
    return;
  }

  // In SCT, leaderboards do no have a parent page, so redirect to home
  if (isRoute(route, params, 'leaderboards') && !parent) {
    window.__router.navigate('home', {});
    return;
  }

  if (isRoute(route, params, 'search')) {
    window.__router.navigate('home', {}, true);
    return;
  }

  // LessonReview has no parent - it shouldn't rely on history to go back.
  // We will force to go back to lesson to ensure proper cleanup.
  if (isRoute(route, params, 'lessonReview') && !parent) {
    const { lessonId } = params as RouteParams<'lessonReview'>;
    window.__router.navigate('lesson', { id: lessonId }, true);
    return;
  }

  if (!parent) {
    window.history.back();
    return;
  }

  window.__router.navigate(parent.route, parent.params, true);
}

export function* watchGoBack() {
  yield takeLatest(NavigationActionTypes.GO_BACK, handleGoBack);
}
