import type { ArgumentTypes, TypeOfClassMethod } from '@edapp/utils';
import { createAction } from '@edapp/utils';
import type { NewAppRouter } from '@maggie/core/router/router';
import type { RouteName, RouteParams } from '@maggie/core/router/types';
import type { LoginRouteError } from '@maggie/store/session/types';
import type { History } from 'history';

import type { NavigationState } from './types';

enum NavigationActionTypes {
  /**
   * This is the new route
   *
   * It should be called after the browser hash route has already been updated
   * by Backbone.History
   */
  DID_NAVIGATE_ROUTE = 'DID_NAVIGATE_ROUTE',

  /**
   * In case it doesn't match any of the existing routes
   */
  FALLBACK_DEFAULT_ROUTE = 'FALLBACK_DEFAULT_ROUTE',

  /**
   * The action that calls the logic to go back.
   * It finds the parent and navigates to it.
   */
  GO_BACK = 'GO_BACK',

  /**
   * All items below are actions that get triggered from the main `DID_NAVIGATE_ROUTE` saga.
   *
   * They are mainly used to save information on navigation state. Like ids, etc.
   */
  DID_NAVIGATE_HOME = 'DID_NAVIGATE_HOME',
  DID_NAVIGATE_PLAYLIST = 'DID_NAVIGATE_PLAYLIST',
  DID_NAVIGATE_COURSE_COLLECTION = 'DID_NAVIGATE_COURSE_COLLECTION',
  DID_NAVIGATE_COURSE = 'DID_NAVIGATE_COURSE',
  DID_NAVIGATE_LESSON = 'DID_NAVIGATE_LESSON',
  DID_NAVIGATE_LESSON_PLAY = 'DID_NAVIGATE_LESSON_PLAY',
  DID_NAVIGATE_DISCUSSION = 'DID_NAVIGATE_DISCUSSION',
  DID_NAVIGATE_ASSESSMENT = 'DID_NAVIGATE_ASSESSMENT',
  DID_NAVIGATE_THREAD = 'DID_NAVIGATE_THREAD',
  DID_NAVIGATE_SUBMISSION = 'DID_NAVIGATE_SUBMISSION',
  DID_NAVIGATE_CONFERENCE = 'DID_NAVIGATE_CONFERENCE',
  DID_NAVIGATE_BRIEFCASE = 'DID_NAVIGATE_BRIEFCASE',
  DID_NAVIGATE_BRIEFCASE_DOCUMENT = 'DID_NAVIGATE_BRIEFCASE_DOCUMENT',
  DID_NAVIGATE_LOGIN = 'DID_NAVIGATE_LOGIN',
  DID_NAVIGATE_CONTENT_LIBRARY_COURSE = 'DID_NAVIGATE_CONTENT_LIBRARY_COURSE',
  DID_NAVIGATE_CONTENT_LIBRARY_SECTION = 'DID_NAVIGATE_CONTENT_LIBRARY_SECTION',
  DID_NAVIGATE_REGISTER = 'DID_NAVIGATE_REGISTER',
  DID_NAVIGATE_SSO_LOGIN_FAILURE = 'DID_NAVIGATE_SSO_LOGIN_FAILURE',
  DID_NAVIGATE_SSO_LOGIN = 'DID_NAVIGATE_SSO_LOGIN',
  DID_NAVIGATE_RAPID_REFRESH = 'DID_NAVIGATE_RAPID_REFRESH',
  DID_NAVIGATE_SOCIAL_LEARNING = 'DID_NAVIGATE_SOCIAL_LEARNING',
  DID_NAVIGATE_SOCIAL_LEARNING_SLIDE = 'DID_NAVIGATE_SOCIAL_LEARNING_SLIDE',
  DID_NAVIGATE_LAUNCH_LESSON = 'DID_NAVIGATE_LAUNCH_LESSON',
  DID_NAVIGATE_OBSERVATION = 'DID_NAVIGATE_OBSERVATION',
  DID_NAVIGATE_OBSERVATION_FEEDBACK = 'DID_NAVIGATE_OBSERVATION_FEEDBACK',
  DID_NAVIGATE_OBSERVATION_RESULTS = 'DID_NAVIGATE_OBSERVATION_RESULTS',
  DID_NAVIGATE_AWARDED_CERTIFICATES = 'DID_NAVIGATE_AWARDED_CERTIFICATES',
  DID_NAVIGATE_GROUP_TRAINING = 'DID_NAVIGATE_GROUP_TRAINING',
  DID_NAVIGATE_ACCOUNT_SETTINGS = 'DID_NAVIGATE_ACCOUNT_SETTINGS',

  SET_COURSE_ID = 'SET_COURSE_ID',
  SET_LESSON_ID = 'SET_LESSON_ID'
}

const NavigationActions = {
  didNavigateRoute: (...args: ArgumentTypes<TypeOfClassMethod<NewAppRouter, 'navigate'>>) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_ROUTE, { route: args[0], params: args[1] }),

  fallbackDefaultRoute: () => createAction(NavigationActionTypes.FALLBACK_DEFAULT_ROUTE),

  didNavigateToHome: () => createAction(NavigationActionTypes.DID_NAVIGATE_HOME, {}),

  didNavigateToPlaylist: (playlistId: string) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_PLAYLIST, { playlistId }),

  didNavigateToCourseCollection: (courseCollectionId: string) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_COURSE_COLLECTION, { courseCollectionId }),

  didNavigateToCourse: (courseId: string, from: NavigationState['course']['from']) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_COURSE, { courseId, from }),

  didNavigateToLesson: (lessonId: string, slideId?: string) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_LESSON, { lessonId, slideId }),

  didNavigateToLessonPlay: (lessonId: string, slideIndex?: number) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_LESSON_PLAY, { lessonId, slideIndex }),

  didNavigateToDiscussion: (discussionId: string) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_DISCUSSION, { discussionId }),

  didNavigateToAssessment: (assessmentId: string) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_ASSESSMENT, { assessmentId }),

  didNavigateToThread: (postId: string, discussionId: string) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_THREAD, { postId, discussionId }),

  didNavigateToSubmission: (postId: string, assessmentId: string) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_SUBMISSION, { postId, assessmentId }),

  didNavigateToConference: (conferenceId: string) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_CONFERENCE, { conferenceId }),

  didNavigateToBriefcase: (courseId: string) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_BRIEFCASE, { courseId }),

  didNavigateToBriefcaseDocument: (courseId: string, documentId: string) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_BRIEFCASE_DOCUMENT, { courseId, documentId }),

  didNavigateToLogin: (error?: LoginRouteError, page?: string) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_LOGIN, { error, page }),

  didNavigateToContentLibraryCourse: (courseId: string) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_CONTENT_LIBRARY_COURSE, { courseId }),

  didNavigateToContentLibrarySection: (sectionId: string) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_CONTENT_LIBRARY_SECTION, { sectionId }),

  didNavigateToRegister: (code: string, email?: string, courseId?: string) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_REGISTER, { code, email, courseId }),

  didNavigateToSSOLoginFailure: () =>
    createAction(NavigationActionTypes.DID_NAVIGATE_SSO_LOGIN_FAILURE),

  didNavigateToSSOLogin: (token?: string, magicLink?: boolean, companyId?: string) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_SSO_LOGIN, {
      token,
      magicLink,
      companyId
    }),

  didNavigateToRapidRefresh: (id: string, session: number) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_RAPID_REFRESH, { id, session }),

  didNavigateToSocialLearning: (lessonId: string) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_SOCIAL_LEARNING, { lessonId }),

  didNavigateToSocialLearningSlide: (lessonId: string, slideId: string) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_SOCIAL_LEARNING_SLIDE, { lessonId, slideId }),

  didNavigateToLaunchLesson: (lessonId: string) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_LAUNCH_LESSON, { lessonId }),

  didNavigateToObservation: (observationId: string) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_OBSERVATION, { observationId }),

  didNavigateToObservationFeedback: (observationId: string) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_OBSERVATION_FEEDBACK, {
      observationId
    }),

  didNavigateToObservationResults: () =>
    createAction(NavigationActionTypes.DID_NAVIGATE_OBSERVATION_RESULTS),

  didNavigateToAwardedCertificates: () =>
    createAction(NavigationActionTypes.DID_NAVIGATE_AWARDED_CERTIFICATES),

  didNavigateToGroupTraining: (courseId: string, sessionId: string) =>
    createAction(NavigationActionTypes.DID_NAVIGATE_GROUP_TRAINING, { courseId, sessionId }),

  didNavigateToAccountSettings: () =>
    createAction(NavigationActionTypes.DID_NAVIGATE_ACCOUNT_SETTINGS),
  /**
   * Sets navigation state without triggering any side effects.
   *
   * Workaround for going back from:
   * COURSE (main) | LESSON (detail)
   * to:
   * PLAYLIST (main) | COURSE (detail)
   * when Lesson is accessed from Resume/Start Playlist
   */
  setCourseId: (courseId: string, from: NavigationState['course']['from']) =>
    createAction(NavigationActionTypes.SET_COURSE_ID, { courseId, from }),

  /**
   * Sets navigation state without triggering any side effects.
   *
   * Workaround for going back from
   * LESSON_PLAY
   * to:
   * COURSE (main) | LESSON (detail)
   * when Lesson is completed and we're navigating to next one
   */
  setLessonId: (lessonId: string) =>
    createAction(NavigationActionTypes.SET_LESSON_ID, { lessonId }),

  goBack: <R extends RouteName, P extends RouteParams<R>>(route: R, params: P, history?: History) =>
    createAction(NavigationActionTypes.GO_BACK, { route, params, history })
};

export { NavigationActionTypes, NavigationActions };
