import { t } from 'i18next';

import { HomeScreenWidget } from '@maggie/store/home/types';

const getSectionTitle = (w: HomeScreenWidget) => {
  switch (w) {
    case HomeScreenWidget.UpNextSection:
      return t('home.up-next', { ns: 'learners-experience' });

    case HomeScreenWidget.CoursesInProgressSection:
      return t('home.continue-learning', { ns: 'learners-experience' });

    case HomeScreenWidget.CoursesCompletedSection:
      return t('common.postPill.completed', { ns: 'learners-experience' });

    case HomeScreenWidget.PlaylistsSection:
      return t('playlists.title', { ns: 'learners-experience' });

    case HomeScreenWidget.RapidRefreshSection:
      return t('rapid-refresh.title', { ns: 'learners-experience' });

    case HomeScreenWidget.CoursesAndCourseCollectionsSection:
      return t('menu.item.courses.title', { ns: 'learners-experience' });

    case HomeScreenWidget.CourseCollectionsSection:
      return t('home.collections', { ns: 'learners-experience' });

    case HomeScreenWidget.InductionsSection:
      return t('home.inductions', { ns: 'learners-experience' });

    default:
      return '';
  }
};

export const WidgetUtils = {
  getSectionTitle
};
