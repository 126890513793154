import { put, select, takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import { ErrorLogger } from '@edapp/monitoring';
import { Tracker } from '@maggie/core/tracker/tracker';
import type { LxStoreState } from '@maggie/store/types';

import { CollectionsSelectors } from '../collections/selectors';
import { CourseActions } from '../courses/actions';
import { CourseSelectors } from '../courses/selectors';
import type { CourseType } from '../courses/types';
import { DocumentActions } from '../documents/actions';
import { DocumentSelectors } from '../documents/selectors';
import type { UnlockPayload } from '../types';
import { LessonActionTypes, LessonActions } from './actions';
import { LessonSelectors } from './selectors';
import type { LessonAction } from './types';
import type { LessonProgressType, LessonType } from './types';

function* handleUpdateLessonFailed(action: LessonAction<LessonActionTypes.UPDATE_LESSON_FAILED>) {
  const { lessonId, score } = action.payload;

  const lesson: LessonType = yield select<LxStoreState>(s =>
    LessonSelectors.getLesson(lessonId, s)
  );
  const progress: LessonProgressType = yield select<LxStoreState>(s =>
    LessonSelectors.getLessonProgress(lessonId, s)
  );
  if (!lesson || !progress) {
    ErrorLogger.captureEvent('UPDATE_LESSON_FAILED: has no progress or no lesson?', 'error', {
      lessonId
    });
    return;
  }

  const attemptRestriction = lesson.features.attemptRestrictionApiResource || {};

  const shouldResetCompletions =
    attemptRestriction.resetCourseOnNumberAttempts > 0 &&
    progress.numberOfFailedAttempts >= attemptRestriction.resetCourseOnNumberAttempts;

  if (!shouldResetCompletions) {
    yield put(LessonActions.updateLessonAttemptFailure(lesson.id, score));
  } else {
    const course: CourseType = yield select<LxStoreState>(s =>
      CourseSelectors.getCourse(lesson.courseId, s)
    );

    if (!course) {
      ErrorLogger.captureEvent('UPDATE_LESSON_FAILED: has no course for the lesson', 'error', {
        lessonId,
        courseId: lesson.courseId
      });
      return;
    }

    // Reset progress of lessons
    const lessonIds = course.lessonSummaries.map(lessonSummary => lessonSummary.lessonId);
    yield put(LessonActions.resetLessonsProgress(lessonIds));

    // Raise interaction
    Tracker.trackEvent({
      type: 'event',
      name: 'reset-course-completion',
      id: course.id,
      courseVersionNumber: course.versionNumber
    });

    // Raise segment event
    itly.resetCourseCompletion({ id: course.id });

    // Refresh lesson prereqs
    const otherLessons = course.lessonSummaries.filter(l => l.lessonId !== lesson.id);
    const lessonItemsToUnlock: UnlockPayload[] = yield select<LxStoreState>(
      LessonSelectors.getUnlockPayloadFromPrerequisites(otherLessons)
    );
    yield put(LessonActions.updateLessonsUnlock(lessonItemsToUnlock));

    // Refresh course prereqs
    const courseItemsToUnlock: UnlockPayload[] = yield select<LxStoreState>(
      CollectionsSelectors.getUnlockPayloadFromPrerequisites(course.id)
    );
    yield put(CourseActions.updateCoursesUnlock(courseItemsToUnlock));

    // Refresh briefcase prereqs
    const documentItemsToUnlock: UnlockPayload[] = yield select<LxStoreState>(
      DocumentSelectors.getUnlockPayloadFromPrerequisites(lessonId, course.id)
    );
    yield put(DocumentActions.updateDocumentsUnlock(documentItemsToUnlock));
  }
}

export function* watchUpdateLessonFailed() {
  yield takeLatest(LessonActionTypes.UPDATE_LESSON_FAILED, handleUpdateLessonFailed);
}
