import type { PutEffect, CallEffect } from 'redux-saga/effects';
import { call, put, fork, takeLatest } from 'redux-saga/effects';

import type { ActionFromActionType } from '@edapp/utils';
import { Urls } from '@maggie/store/constants';
import type { InductionsActionsUnionType } from './actions';
import { InductionsActionTypes, InductionsActions } from './actions';

export type InductionsAction<ActionType extends string> = ActionFromActionType<
  InductionsActionsUnionType,
  ActionType
>;

const DEFAULT_PAGE_SIZE = 10;

function* fetchInductionsList(url: string, queryParams: string): Generator<unknown, any, Response> {
  const response = yield call(fetch, `${url}?${queryParams}`, { method: 'GET' });

  if (!response.ok) {
    const error = new Error(`Failed to get the inductions list, status: ${response.status}`);
    throw error;
  }

  return yield response.json();
}

function* handleFetchAllInductions(
  action: InductionsAction<InductionsActionTypes.FETCH_ALL_ASSIGNED_INDUCTIONS>
): Generator<PutEffect<any> | CallEffect, void, any> {
  const { options } = action.payload;

  try {
    const queryParams = new URLSearchParams({
      pageSize: (options.pageSize || DEFAULT_PAGE_SIZE).toString()
    });

    const data = yield call(
      fetchInductionsList,
      Urls.ASSIGNED_INDUCTIONS_LIST,
      queryParams.toString()
    );

    yield put(
      InductionsActions.fetchAllInductionsSuccess(options.pageSize || DEFAULT_PAGE_SIZE, data)
    );
  } catch (error) {
    yield put(InductionsActions.fetchAllInductionsFailure(error));
  }
}

function* watchFetchAllInductions() {
  yield takeLatest(InductionsActionTypes.FETCH_ALL_ASSIGNED_INDUCTIONS, handleFetchAllInductions);
}

const inductionsSagas = [fork(watchFetchAllInductions)];

export { inductionsSagas };
