import styled, { css } from 'styled-components';
import { HStack } from '@edapp/sc-web-ui';
import { rgba } from '@edapp/themes';

export const FullWidthBox = styled(HStack)(
  ({ theme }) => css`
    width: 100%;
    min-width: 250px;
    z-index: 100;
    padding: 0px ${theme._ui.space.s4};
    height: 44px;
    background: ${theme._ui.colors.surface.bg.default};
    box-shadow: 0px ${theme._ui.space.s2} ${theme._ui.space.s4} 0px
      ${rgba(theme.colors.fixedBlack, 0.04)};
  `
);

export const HeaderContent = styled(HStack)`
  width: 100%;
  background: ${({ theme }) => theme._ui.colors.surface.bg.default};
`;
